var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.busy)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Home/Commode2.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("SIDEBAR.DASHBOARD")))])])])]}}],null,false,2562051792)}),_vm._l((_vm.navs),function(nav){return _c('span',{key:nav.name,staticClass:"menu-nav"},[(nav.type === 'menu-section' && _vm.comparePermission(nav.guard))?_c('li',{staticClass:"menu-section"},[_c('h6',{staticClass:"menu-text text-white"},[_vm._v(_vm._s(_vm.$t(nav.text)))]),_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Other2.svg"}})],1)]):_vm._e(),(nav.type === 'menu-nav' && _vm.comparePermission(nav.guard))?_c('router-link',{attrs:{"to":nav.url.path ? nav.url.path : { name: nav.url.name }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":nav.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t(nav.text))+" ")])])])]}}],null,true)}):_vm._e(),(nav.type === 'menu-nav-parent' && _vm.comparePermission(nav.guard))?_c('li',{staticClass:"menu-item menu-item-submenu",class:[_vm.hasActiveChildren(nav.url.path) ? 'menu-item-open' : ''],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":nav.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t(nav.text))+" ")]),_c('i',{staticClass:"menu-arrow"})]),_vm._l((nav.children),function(child){return _c('div',{key:child.text,staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":child.url.path ? child.url.path : { name: child.url.name }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t(child.text))+" ")])])])]}}],null,true)})],1)])})],2):_vm._e()],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }