<template>
  <ul class="menu-nav">
    <li class="menu-item mr-5 mt-2">
      <!--      <img class="img-responsive" src="/media/logos/smart-id-logo.png">-->
      <h6 class="text-uppercase"><strong>{{ profile.organisation }}</strong></h6>
    </li>
    <li v-if="getDevMode" class="menu-item bg-light-info rounded px-2 py-2">
      <span class="font-weight-bolder text-info">DEBUG Mode</span>
    </li>

    <!-- VUE Boostrap (Removed) -->

    <!-- Vuetify (Removed) -->
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  computed: {
    getDevMode() {
      let mode = false;
      if (process.env.NODE_ENV === "development") {
        mode = true;
      }

      return mode;
    },

    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
  },
};
</script>
