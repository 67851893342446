<template>
  <ul class="menu-nav" v-if="!busy">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="/media/svg/icons/Home/Commode2.svg" />
          </span>
          <span class="menu-text">{{ $t("SIDEBAR.DASHBOARD") }}</span>
        </a>
      </li>
    </router-link>

    <!-- begin: generated menu -->
    <span class="menu-nav" v-for="nav in navs" :key="nav.name">
      <!--  begin:: generated menu section    -->
      <li
        v-if="nav.type === 'menu-section' && comparePermission(nav.guard)"
        class="menu-section"
      >
        <h6 class="menu-text text-white">{{ $t(nav.text) }}</h6>
        <span class="svg-icon menu-icon">
          <inline-svg src="/media/svg/icons/General/Other2.svg" />
        </span>
      </li>
      <!--  end:: generated menu section    -->

      <!--  begin:: generated menu item (non-parent)  -->
      <router-link
        v-if="nav.type === 'menu-nav' && comparePermission(nav.guard)"
        :to="nav.url.path ? nav.url.path : { name: nav.url.name }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="svg-icon menu-icon">
              <inline-svg :src="nav.icon" />
            </span>
            <span class="menu-text"> {{ $t(nav.text) }} </span>
          </a>
        </li>
      </router-link>
      <!--  end:: generated menu item (non-parent)  -->

      <!--  begin:: generated menu item (parent)  -->
      <li
        v-if="nav.type === 'menu-nav-parent' && comparePermission(nav.guard)"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="[hasActiveChildren(nav.url.path) ? 'menu-item-open' : '']"
      >
        <a href="#" class="menu-link menu-toggle">
          <span class="svg-icon menu-icon">
            <inline-svg :src="nav.icon" />
          </span>
          <span class="menu-text"> {{ $t(nav.text) }} </span>
          <i class="menu-arrow"></i>
        </a>

        <div
          class="menu-submenu menu-submenu-classic menu-submenu-right"
          v-for="child in nav.children"
          :key="child.text"
        >
          <ul class="menu-subnav">
            <router-link
              :to="child.url.path ? child.url.path : { name: child.url.name }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text"> {{ $t(child.text) }} </span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <!--  begin:: generated menu item (parent)  -->
    </span>
    <!-- end: generated menu -->
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "KTMenu",
  mounted() {
    this.$store.dispatch("generateMenuItems").then((value) => {
      console.log("Generated items", value);
    });
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    /**
     * @param guard assigned to route
     * @returns {boolean}
     */
    comparePermission(guard) {
      console.log(
        "Comparing permission",
        guard.permissions,
        this.permissions,
        this.busy
      );
      let status = true;

      if (guard.permissions.length > 0) {
        const similar = this.permissions.filter((item) => {
          return guard.permissions.includes(item.name);
        });
        console.log("Similar Length", similar);
        status = similar.length > 0;
      }

      console.log("Comparison", status);
      return status;
    },
  },
  computed: {
    ...mapState({
      navs: (state) => state.CoreModule.AsideMenuStore.items,
      permissions: (state) => state.CoreModule.AsideMenuStore.permissions,
      busy: (state) => state.CoreModule.AsideMenuStore.isBusy,
    }),
  },
};
</script>
